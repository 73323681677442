import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'

import { withDefaultLayout } from 'layouts/with-default-layout'
import { ProjectsTile } from 'components'

const GalleryContainer = styled.div`
  position: fixed;
  z-index: 1100;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 90vh;
  overflow: hidden;
  display: inline-flex;
  gap: 20px;
`;

const ImageContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75vw;
    height: inherit;
    max-width: 1250px;
    transition: all .6s;
    &:nth-child(1) {
      transform: translate(-270%, -50%);
    }
    &:nth-child(2) {
      transform: translate(-160%, -50%);
    }
    &:nth-child(3) {
      transform: translate(-50%, -50%);
    }
    &:nth-child(4) {
      transform: translate(60%, -50%);
    }
    &:nth-child(5) {
      transform: translate(170%, -50%);
    }
    & .img-test {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
`;

const GalleryArrow = styled.button`
  position: absolute;
  z-index: 1100;
  top: 0;
  width: 10%;
  height: inherit;
  max-height: inherit;
  border: none;
  background: none;
  opacity: ${({active}) => active ? '1' : '0'};
  transition: all .5s;
  cursor: pointer;
  &:hover:before,
  &:hover:after {
    background-color: white;    
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 50%;
    width: 2px;
    height: 20px;
    transition: all .2s;
    background-color: rgba(255, 255, 255, .55);
    box-shadow: 0 0 3px rgba(0, 0, 0, .8);
  }
  ${({direction}) => direction === 'left' && css`
    left: 0;
    &:before {
      top: 50%;
      transform: rotate(-45deg);
      transform-origin: -3px;
    }
    &:after {
      top: calc(50% - 20px);
      transform: rotate(45deg);
      transform-origin: -3px;
    }
  `}
  ${({direction}) => direction === 'right' && css`
    right: 0;
    &:before {
      top: 50%;
      transform: rotate(45deg);
      transform-origin: 5px;
    }
    &:after {
      top: calc(50% - 20px);
      transform: rotate(-45deg);
      transform-origin: 5px;
    }
  `}
`;

const CloseButton = styled.div`
  position: fixed;
  z-index: 1200;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 0;
  background-color: rgba(35, 35, 35, .75);
  border-radius: 6px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: inherit;
    height: 2px;
    display: block;
    margin: 0 auto;
    background-color: #f5f5f590;
    transition: all 0.3s;
  }
  &:before {
    top: calc(50% - 1px);
    transform: rotate(45deg);
  }
  &:after {
    bottom: calc(50% - 1px);
    transform: rotate(-45deg);
  }
`;

const GalleryBackground = styled.div`
  background-color: rgba(35, 35, 35, .75);
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
`

class Logotypes extends Component {
  state = {
    gallery: null,
  }
  handleGallery = (galleryList, imageId) => {
    if (galleryList === null) {
      return this.setState({gallery: null});
    };
    const galleryImages = [
        galleryList[imageId - 2],
        galleryList[imageId - 1],
        galleryList[imageId],
        galleryList[imageId + 1],
        galleryList[imageId + 2],
      ]
      .map((image) => {
        if (!image) {
          return {
            id: Math.random(),
            desktopSrc: '',
            alt: 'no_image',
          }
        } else {
          return {
            id: image.id,
            src: image.sizes.src,
            alt: image.title,
          }
        }
      })
      .map((image, index) => {
        return (
          <ImageContainer key={image.id}>
            {image.src && (
              <>
                {!(imageId === 0) && (
                  <GalleryArrow direction='left' active={index === 2} onClick={() => this.handleGallery(galleryList, imageId-1)} />
                )}
                <div className='img-test' >
                  <img 
                    src={image.src} 
                    alt={image.alt}
                  />
                </div>

                {!(imageId === galleryList.length-1) && (
                  <GalleryArrow direction='right' active={index === 2} onClick={() => this.handleGallery(galleryList, imageId+1)} />
                )}
              </>
            )}
          </ImageContainer>
        )});
    this.setState({gallery: galleryImages});
  }
  render () {
    const { pageContext: { language }, data: { logotypesData } } = this.props;
    const logotypes = logotypesData.logotypes.map(({ id, name, gallery, image, imageHover }) => ({
      id,
      name,
      gallery,
      image: image && (image.file || {}).url,
      imageHover: imageHover && (imageHover.file || {}).url,
    }));
    return (
      <>
        {this.state.gallery && (
          <>
            <GalleryBackground  onClick={() => this.handleGallery(null)} />
            <CloseButton onClick={() => this.handleGallery(null)} />
            <GalleryContainer>
              {this.state.gallery}
            </GalleryContainer>
          </>
        )}
        <ProjectsTile
          projects={logotypes}
          language={language}
          slabHeight={64.51}
          withInfiniteScroll={true}
          handleGallery={this.handleGallery}
        />
      </>
    )
  }
}

export default withDefaultLayout(Logotypes)

export const query = graphql`
  query ($language: String!) {
    logotypesData: contentfulLogotypesPage {
      logotypes {
        id
        name
        image {
          id
          file {
            url
          }
        }
        imageHover {
          id
          file {
            url
          }
        }
        gallery {
          id
          title
          sizes(maxWidth: 850, toFormat: WEBP) {
            src
          }
        }
      }
    }
    footer: contentfulFooter(node_locale: { eq: $language }) {
      heading
      firstAddress {
        firstAddress
      }
      secondAddress {
        secondAddress
      }
      writeToMe
      instagramUrl
      facebookUrl
    }
  }
`
